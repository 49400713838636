import React from 'react'
import classnames from 'classnames'
import IconAlert from 'design-system/src/components/Icon/IconAlert'
import IconInformation from 'design-system/src/components/Icon/IconInformation'
import styles from './index.module.css'

interface IInfoProps {
  message: string
  type: string
  inline?: boolean
}

export const Info: React.FC<IInfoProps> = ({
  message,
  type,
  inline = false,
}: IInfoProps) => (
  <div className={classnames(styles.infoContainer, inline && styles.inline)}>
    {type === 'error' ? <IconAlert warning /> : <IconInformation />}
    <span className={styles.text}>{message}</span>
  </div>
)

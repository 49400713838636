import { DATE_FORMAT_WITH_UTC, LOCALISED_DATE_FORMAT } from '../constants'
import { DateTime } from 'luxon'

export function getAgentDateTimeAndZone(date: string) {
  const agentDateTime = DateTime.fromISO(date)
  return agentDateTime.toFormat(LOCALISED_DATE_FORMAT)
}

export function getBookingDateTimeAndZone(date: string) {
  const bookingDateTime = DateTime.fromISO(date, { setZone: true })
  return bookingDateTime.toFormat(DATE_FORMAT_WITH_UTC)
}

import { Service, Ride } from '../src/generated/graphql'

export const isRideType = (
  service: Omit<Service, 'id' | 'booking'>
): service is Ride => {
  return (
    ((service as Ride).category,
    (service as Ride).chauffeur,
    (service as Ride).date,
    (service as Ride).distance,
    (service as Ride).dropoff,
    (service as Ride).duration,
    (service as Ride).passengers,
    (service as Ride).pickup,
    (service as Ride).rating,
    (service as Ride).status) !== undefined
  )
}

import React from 'react'

let ADMIN_FE_URL = ''

if (!process.env.NEXT_PUBLIC_ADMIN_FE_URL) {
  throw new Error('NEXT_PUBLIC_ADMIN_FE_URL is not defined.')
} else {
  ADMIN_FE_URL = process.env.NEXT_PUBLIC_ADMIN_FE_URL
}

export const ExternalLink = ({
  linkTo,
  section,
  children,
}: {
  linkTo: string
  section: string
  children: React.ReactChild
}) => {
  if (!linkTo) {
    return <>{children}</>
  }
  return (
    <a
      href={`${ADMIN_FE_URL}/admin/${section}/${linkTo}`}
      target="_blank"
      rel="noreferrer"
    >
      <div data-test-id="details-item">{children}</div>
    </a>
  )
}
